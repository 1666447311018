<template>
  <section class="usability">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.main.usability.mainTitle")
        }}</h2>
      <ul class="usability__list">
        <li class="usability__item" :class="{'usability__item--odd' : use.id % 2 === 0}" v-for="use in usabilityList" :key="use.id">
          <img class="usability__image" :class="{'usability__image--odd' :use.id % 2 === 0} " :src="use.src" alt="icon">
          <div class="usability__content">
            <h3 class="usability__subtitle">{{ use.title }}</h3>
            <p class="usability__description" lang="uk">{{ use.description }}</p>
          </div>
        </li>
      </ul>
      <div class="usability__footer">
        <h2 class="section-title usability__footer-title">{{
            $vuetify.lang.t("$vuetify.main.usability.sectionSubtitle")
          }}</h2>
        <a :href="linkToDashboard" target="_blank">
          <button class="main-btn usability__btn">{{
              $vuetify.lang.t('$vuetify.main.usability.button')
            }}</button>
        </a>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UsabilitySection',
  data () {
    return {
      linkToDashboard: process.env.VUE_APP_DASHBOARD + '/patientRegForm'
    }
  },
  computed: {
    usabilityList () {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.main.usability.usabilityList.itemTitle1'),
          description: this.$vuetify.lang.t('$vuetify.main.usability.usabilityList.itemDescription1'),
          src: require('@/assets/images/usability-1.png')
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.main.usability.usabilityList.itemTitle2'),
          description: this.$vuetify.lang.t('$vuetify.main.usability.usabilityList.itemDescription2'),
          src: require('@/assets/images/usability-2.png')
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.main.usability.usabilityList.itemTitle3'),
          description: this.$vuetify.lang.t('$vuetify.main.usability.usabilityList.itemDescription3'),
          src: require('@/assets/images/usability-3.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  text-align: center;
}
.usability {
  padding: 60px 0 80px;
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-top: -20px;
    text-align: left;
  }
  &__item--odd {
    align-self: flex-end;
  }
  &__image {
    position: relative;
    top: 20px;
    left: 20px;
    max-width: 200px;
    height: auto;
    margin-right: 30px;
  }
  &__image--odd {
    order: 1;
    left: unset;
    right: 20px;
    margin-left: 30px;
  }
  &__content {}
  &__subtitle {
    font-size: 1.6rem;
    font-weight: normal;
    color: $lightColor;
  }
  &__description {
    line-height: normal;
    font-size: 1rem;
    max-width: 470px;
    padding-left: 50px;
    color: $darkTextColor;
    hyphens: auto;
    margin-top: 15px;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px;
  }
  &__footer-title {
    color: $darkTextColor;
  }
  &__btn {
    font-size: 1.6rem;
    font-weight: normal;
    text-transform: capitalize;
    letter-spacing: unset;
    color: #ffffff !important;
    padding: 0 75px;
  }
  @media screen and (max-width: 768px) {
    &__item {
      margin-top: unset;
      align-items: self-start;
    }
    &__image {
      max-width: 100px;
    }
    &__description {
      padding-left: 0;
    }
    &__footer {
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
