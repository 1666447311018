<template>
  <div>
    <preview-section></preview-section>
    <about-section></about-section>
    <check-section></check-section>
    <steps-section></steps-section>
    <usability-section></usability-section>
    <contacts-section></contacts-section>
  </div>
</template>

<script>
import PreviewSection from '@/components/main/MainPage/PreviewSection'
import AboutSection from '@/components/main/MainPage/AboutSection'
import CheckSection from '@/components/main/MainPage/CheckSection'
import StepsSection from '@/components/main/MainPage/StepsSection'
import UsabilitySection from '@/components/main/MainPage/UsabilitySection'
import ContactsSection from '@/components/main/MainPage/ContactsSection'
export default {

  name: 'MainPage',
  components: {
    PreviewSection,
    AboutSection,
    CheckSection,
    StepsSection,
    UsabilitySection,
    ContactsSection
  },
  metaInfo () {
    return {
      title: 'Психолог онлайн┃Відеоконсультації з психологом',
      meta: [
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'description',
          content: 'Платформа – спільнота професійних психологів та \n' +
            'психотерапевтів. Підібрати свого психолога ⋄ Конфіденційні \n' +
            'відеоконсультації в особистому кабінеті ⋄ Єдина ціна \n' +
            'консультації – 890 грн ⋄ Оплата на сайті.'
        },
        {
          name: 'keywords',
          content: 'психолог онлайн, психолог онлайн україна, сімейний \n' +
            'психолог, дитячий психолог, підлітковий психолог, сексолог, \n' +
            'психотерапевт, психотерапія, гештальт терапія, \n' +
            'транзакційний аналіз, психоаналіз'
        }
      ]
    }
  }
}
</script>
<style></style>
