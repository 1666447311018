<template>
  <section class="steps">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.main.steps.sectionTitle")
        }}</h2>
      <ul class="steps__list">
        <li class="steps__item" v-for="step in stepsList" :key="step.id">
          <div class="steps__img-wrapper">
            <img class="steps__img-top" :src="step.icon" alt="">
          </div>
          <h4 class="steps__subtitle">{{ step.title }}</h4>
          <p class="steps__description">{{ step.description }}</p>
          <img class="steps__img-bottom" :src="step.number" alt="number">
        </li>
      </ul>
      <p class="decor-text steps__decor-text">{{
          $vuetify.lang.t("$vuetify.main.steps.decorText")
        }}</p>
      <a :href="linkToDashboard" target="_blank">
        <button class="main-btn steps__main-btn">{{
            $vuetify.lang.t("$vuetify.main.steps.button")
          }}</button>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'StepsSection',
  data () {
    return {
      linkToDashboard: process.env.VUE_APP_DASHBOARD + '/patientRegForm'
    }
  },
  computed: {
    stepsList () {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.main.steps.stepsList.itemTitle1'),
          description: this.$vuetify.lang.t('$vuetify.main.steps.stepsList.itemDescription1'),
          icon: require('@/assets/images/1-top.png'),
          number: require('@/assets/images/1-bottom.png')
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.main.steps.stepsList.itemTitle2'),
          description: this.$vuetify.lang.t('$vuetify.main.steps.stepsList.itemDescription2'),
          icon: require('@/assets/images/2-top.png'),
          number: require('@/assets/images/2-bottom.png')
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.main.steps.stepsList.itemTitle3'),
          description: this.$vuetify.lang.t('$vuetify.main.steps.stepsList.itemDescription3'),
          icon: require('@/assets/images/3-top.png'),
          number: require('@/assets/images/3-bottom.png')
        },
        {
          id: 4,
          title: this.$vuetify.lang.t('$vuetify.main.steps.stepsList.itemTitle4'),
          description: this.$vuetify.lang.t('$vuetify.main.steps.stepsList.itemDescription4'),
          icon: require('@/assets/images/4-top.png'),
          number: require('@/assets/images/4-bottom.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  text-align: center;
}
.steps {
  padding: 50px 0 60px;
  background-color: $backgroundColor;

  &__list {
    margin-top: 50px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 30px;
  }

  &__item {
    position: relative;
    width: 100%;
    max-width: 470px;
    padding-left: 55px;
    padding-right: 55px;
    border: 2px solid $lightColor;
    border-radius: 22px;
    z-index: 0;
    text-align: left;
  }

  &__img-wrapper {
    position: absolute;
    float: right;
    top: 0;
    right: 0;
    width: 55px;
    height: 55px;
    background-color: $lightColor;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &__img-top {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate3d(-5px, 5px, 0);
    max-width: 40px;
    height: auto;
  }
  &__img-bottom {
    position: absolute;
    bottom: -2px;
    left: 0;
    max-width: 50px;
    transform: translateX(-20%);
    z-index: 2;
    background-color: $backgroundColor;
  }
  &__subtitle {
    padding-top: 10px;
    font-size: 1.3rem;
    font-weight: 400;
    color: $lightColor;
  }
  &__description {
    font-size: 1rem;
    line-height: normal;
    font-weight: 400;
    padding-top: 10px;
    color: $darkTextColor;
  }
  &__decor-text {
    position: relative;
    margin: 60px auto 0;
    max-width: 560px;
    &::after {
      position: absolute;
      content: "";
      background-image: url("../../../assets/images/arrow.png");
      width: 120px;
      height: 120px;
      top: 80%;
      left: 100%;
      background-size: contain;
    }
  }
  &__main-btn {
    margin: 60px auto 0;
    padding: 0 100px;
  }
  @media screen and (max-width: 768px) {
    &__decor-text::after {
      content: none;
    }
  }
}
</style>
